<template>
    <div class="downloadPage">
        <div class="downloadBox">
            <div class="downloadBoxBtn">
                <!-- style="background-image:url(../../assets/android.png)" -->
                <div class="downloadBtn">
                    <img src="../../assets/android.png" alt="">
                </div>
                <div class="downloadBtn">
                    <img src="../../assets/ios.png" alt="">
                </div>
            </div>
            <img class="downloadQR" src="" alt="">
        </div>
    </div>
</template>

<script>

export default {


    data() {
        return {

        };
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.downloadPage {
    position: relative;
    width: 100%;
    // height: calc(900px - 97px);
    height: 900px;
    background-image: url(../../assets/downloadBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .downloadBox {
        display: flex;
        align-items: center;
        position: absolute;
        height: 130px;
        z-index: 9;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;

        .downloadBoxBtn {
            margin-right: 50px;
            width: 170px;

            .downloadBtn {
                cursor: pointer;
                display: flex;
                height: 45px;
                margin-bottom: 30px;

                &:nth-child(2) {
                    margin-bottom: 0;
                }

                img {
                    width: 170px;
                    height: 45px;
                }
            }
        }
    }

    .downloadQR {
        width: 130px;
        height: 130px;
    }
}
</style>